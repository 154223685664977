/*
@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("./assets/fonts/HelveticaNeueLTStd-Bd.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("./assets/fonts/HelveticaNeueLTStd-Md.otf") format("opentype");
  font-weight: 500;
}
*/
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}
.mobile-center {
  width: 100%;
  text-align: center;
}
